import IconNotificationsOutline from "~/img/icons/notifications_outline.svg";
import { Match, Switch } from "solid-js";

export default function PreviewText(props: { type?: string }) {
  return (
    <>
      <div class="preview">
        <i aria-hidden="true" class="cog-icon">
          <IconNotificationsOutline />
        </i>
        <Switch
          fallback={
            <>
              <div class="col">
                <p>
                  <strong>Soyez les premiers informés !</strong>
                </p>
                <p>
                  Profitez du meilleur choix en contactant votre conseiller.
                </p>
              </div>
            </>
          }
        >
          <Match when={props.type === "info-window"}>
            <p>
              <strong>Soyez les premiers informés !</strong>
            </p>
          </Match>
        </Switch>
      </div>
    </>
  );
}
