import type { BrandReferenceField } from "~/types/drupal_jsonapi";
import { Show } from "solid-js";

export default function BrandProgram(props: {
  brand: BrandReferenceField;
  isSmallVersion?: boolean;
}) {
  const calculatedHeight = () => {
    if (
      props.brand.field_logo_main &&
      props.brand.field_logo_main.meta &&
      props.brand.field_logo_main.meta.width &&
      props.brand.field_logo_main.meta.height
    ) {
      return (
        ((props.isSmallVersion ? 100 : 150) *
          props.brand.field_logo_main.meta?.height) /
        props.brand.field_logo_main.meta?.width
      );
    }

    return props.isSmallVersion ? 15 : 22;
  };
  return (
    <>
      <span class="brand" data-test="brand">
        <Show
          when={
            props.brand.field_logo_main && props.brand.field_logo_main.uri.url
          }
          fallback={props.brand.name}
        >
          <img
            src={props.brand.field_logo_main!.uri.url}
            height={calculatedHeight()}
            width={props.isSmallVersion ? 100 : 150}
            alt={props.brand.field_logo_main!.meta?.alt}
          />
        </Show>
      </span>
    </>
  );
}
