import { Show } from "solid-js";

import type { ProgramWrapper } from "~/utils/program_wrapper";

export default function Regulations(props: { wrapper: ProgramWrapper }) {
  return (
    <>
      <ul class="regulation" data-test="regulations">
        <Show when={props.wrapper.program.field_disp_grid_vat_inc_reduced}>
          <li>
            <span>TVA réduite</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_vat_inc_mastered}>
          <li>
            <span>Prix maîtrisés</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_vat_inc_brs}>
          <li>
            <span>Prix BRS</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_bare_ownership}>
          <li>
            <span>Nue-propriété</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_ex}>
          <li>
            <span>LMNP géré</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_lmnp_vat_inc}>
          <li>
            <span>LMNP</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_disp_grid_patrimonial}>
          <li>
            <span>Patrimonial</span>
          </li>
        </Show>
        <Show when={props.wrapper.program.field_is_lli}>
          <li>
            <span>LLI, LLI Meublée</span>
          </li>
        </Show>
      </ul>
    </>
  );
}
