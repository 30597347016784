import { createMemo, Show } from "solid-js";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import { getGrid, isOutOfStock, isPreview } from "~/utils/helper_program";
import type { SearchParams } from "~/types/drupal_jsonapi";

export default function Typologies(props: {
  wrapper: ProgramWrapper;
  searchParams?: SearchParams;
}) {
  const grid = createMemo(() => getGrid(props.wrapper, props.searchParams));

  const lotsCount = createMemo(() =>
    grid().reduce((count, typology) => count + typology.lots.length, 0),
  );

  return (
    <>
      <div class="info-box">
        <Show
          when={props.wrapper.program.field_address_hide}
          fallback={
            <address
              itemprop="address"
              itemscope
              itemtype="https://schema.org/PostalAddress"
            >
              <span itemprop="postalCode">
                {props.wrapper.program.field_postal_code}
              </span>{" "}
              <span itemprop="addressLocality">
                {props.wrapper.program.field_city}
              </span>
            </address>
          }
        >
          <Show when={props.wrapper.program.field_address_override}>
            <address
              itemprop="address"
              itemscope
              itemtype="https://schema.org/PostalAddress"
            >
              <span itemprop="addressLocality">
                {props.wrapper.program.field_address_override}
              </span>
            </address>
          </Show>
        </Show>

        <div class="flex-row">
          <h2>{props.wrapper.program.title}</h2>

          <Show
            when={!isPreview(props.wrapper) && !isOutOfStock(props.wrapper)}
          >
            <span class="summary">
              {lotsCount()} logement{lotsCount() > 1 ? "s" : null}
            </span>
          </Show>
        </div>
      </div>
    </>
  );
}
