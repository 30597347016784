import { Show } from "solid-js";
import imgProxy from "~/utils/imgproxy";

import type { ImageField } from "~/types/drupal_jsonapi";

export default function Visual(props: { imageDesktop: ImageField[] }) {
  return (
    <>
      <picture>
        <Show
          when={props.imageDesktop && props.imageDesktop!.length > 0}
          fallback={
            <img
              src={imgProxy(
                "/images/default-program-visual-desktop.png",
                "size:654:360/resizing_type:fill",
              )}
              alt=""
              height="180"
              width="327"
              loading="lazy"
            />
          }
        >
          <img
            src={imgProxy(
              props.imageDesktop![0].uri.url,
              "size:654:360/resizing_type:fill",
            )}
            alt={props.imageDesktop![0].meta?.alt}
            height="180"
            width="327"
            loading="lazy"
          />
        </Show>
      </picture>
    </>
  );
}
