import Visual from "~/components/Cards/Components/Visual";
import { Show } from "solid-js";
import * as helper from "~/utils/helper_program";
import LastOpportunities from "~/components/Cards/Components/LastOpportunities";
import CardOffer from "~/components/Cards/Components/CardOffer";
import CardPush from "~/components/Cards/Components/CardPush";
import { urlRs } from "~/utils/url";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import { isLastOpportunity } from "~/utils/helper_program";
import Regulations from "~/components/Cards/Components/Regulations";
import NouveauNeufLogos from "~/components/shared/NouveauNeufLogos";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import BrandProgram from "~/components/shared/BrandProgram";

export default function VisualContainer(props: { wrapper: ProgramWrapper }) {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <div class="visual-container">
        <Visual imageDesktop={props.wrapper.program.field_image_desktop!} />
        <CardPush pushTop={props.wrapper.program.field_push_top} />
        <Show
          when={
            !helper.isPreview(props.wrapper) &&
            !helper.isOutOfStock(props.wrapper) &&
            isLastOpportunity(props.wrapper)
          }
        >
          <LastOpportunities
            hasAnimation={!!props.wrapper.program?.animation}
          />
        </Show>
        <Show when={props.wrapper.program?.animation}>
          <CardOffer />
        </Show>

        <Regulations wrapper={props.wrapper} />

        <Show
          when={
            settings.nouveau_neuf_enabled &&
            props.wrapper.program.field_segments_mkg &&
            props.wrapper.program.field_segments_mkg!.length > 0
          }
        >
          <NouveauNeufLogos
            zone="card"
            segmentsMkg={props.wrapper.program.field_segments_mkg!}
          />
        </Show>

        <BrandProgram
          brand={props.wrapper.program.field_brand}
          isSmallVersion
        />

        <a
          href={urlRs("programs", props.wrapper.program.path.alias)}
          class="more-link"
        >
          En savoir plus
        </a>
      </div>
    </>
  );
}
