import { For, Match, Show, Switch } from "solid-js";

import type { TermEntityReferenceField } from "~/types/drupal_jsonapi";

import "./NouveauNeufLogos.css";

import PastilleAccess from "~/img/logos/pastille-access.svg";
import PastilleCogedimAvantages from "~/img/logos/pastille-avantages.svg";
import PastilleCogedimSignature from "~/img/logos/pastille-cogedim-signature.svg";
import { urlRs } from "~/utils/url";

export default function NouveauNeufLogos(props: {
  zone: string;
  segmentsMkg: TermEntityReferenceField[];
}) {
  return (
    <>
      <div class="logos-nouveau-neuf" data-test="logos-nn">
        <For each={props.segmentsMkg}>
          {(segmentMkg: TermEntityReferenceField) => (
            <span
              class={`logo logo-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
              data-test={`logo-nn-${segmentMkg.name.toLowerCase().replace(" ", "-")}`}
            >
              <Show when={props.zone === "program"}>
                <Switch>
                  <Match when={segmentMkg.name === "Access"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-access/",
                      )}
                      title="Access"
                    >
                      <PastilleAccess />
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Avantages"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-avantages/",
                      )}
                      title="Cogedim Avantages"
                    >
                      <PastilleCogedimAvantages />
                    </a>
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <a
                      href={urlRs(
                        "landings",
                        "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-signature/",
                      )}
                      title="Signature"
                    >
                      <PastilleCogedimSignature />
                    </a>
                  </Match>
                </Switch>
              </Show>
              <Show when={props.zone === "card"}>
                <Switch>
                  <Match when={segmentMkg.name === "Access"}>
                    <PastilleAccess />
                  </Match>
                  <Match when={segmentMkg.name === "Avantages"}>
                    <PastilleCogedimAvantages />
                  </Match>
                  <Match when={segmentMkg.name === "Cogedim Signature"}>
                    <PastilleCogedimSignature />
                  </Match>
                </Switch>
              </Show>
            </span>
          )}
        </For>
      </div>
    </>
  );
}
